<template>
  <section class="Observe">
    <router-view/>
  </section>
</template>
<script>

  export default {
    name: 'Observe',
    components: {
    },

    computed: {

    },
    data: () => {
      return {

      }
    },
    methods: {

    },

  }
</script>
<style scoped lang="less">
.Observe {

}
</style>